<template>
  <section class="mb-15">
    <v-container>
      <v-img
        :src="
          banner != null
            ? $store.state.imagePath + banner.image_path
            : '@/assets/banner04.png'
        "
        class="my-10 mb-15"
      />
      <v-row>
        <!------------------------------------------
            ##  filtro desabilitado temporariamente  START  
            -------------------------------------------->
        <!-- <v-col v-if="!$vuetify.breakpoint.mobile" cols="3" md="3">
                <ProductsFilter />
            </v-col>
            <v-col align="center" v-else cols="12">
                <v-dialog v-model="filter">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-on="on" v-bind="attrs" rounded color="primary">Filtro</v-btn>
                    </template>
                    <ProductsFilter />
                </v-dialog>
            </v-col> -->
        <!------------------------------------------
            ##  filtro desabilitado temporariamente  FINISH  
            -------------------------------------------->
        <v-col
          cols="12"
          md="12"
        >
          <v-row>
            <v-col
              v-for="(product, idx) in products"
              :key="idx"
              cols="6"
              md="2"
            >
              <ProductCard
                :minHeight="$vuetify.breakpoint.mobile ? 200 : 280"
                :product="product"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  components: {
    // ProductsFilter: () => import('@/components/web/products/ProductsFilter'),
    ProductCard: () => import('@/components/web/layout/widgets/ProductCard'),
  },
  data: () => ({
    routes: [{ title: 'Produtos', route: '/products' }],
    filter: false,

    products: [],
    banner: null,
  }),
  methods: {
    getProducts() {
      this.$api
        .post('products/site/index_all', this.$store.state.productsSearch)
        .then((res) => {
          this.products = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getAllProducts() {
      this.$api
        .get('products/site')
        .then((res) => {
          this.products = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getBanner() {
      this.$api
        .get('products/banner')
        .then((res) => {
          this.banner = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
  // beforeUpdate() {
  //     if(this.$store.state.productsSearch.type != null) {
  //         this.getProducts()
  //     }else{
  //         this.getAllProducts()
  //     }
  // },
  mounted() {
    this.getBanner()

    if (this.$store.state.productsSearch.type != null) {
      this.getProducts()
    } else {
      this.getAllProducts()
    }
    this.$store.commit('setBreadcrumbs', this.routes)
  },
}
</script>
